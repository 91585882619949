@use "./_colors.scss" as *;

// Input styling
input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:required {
    background-color: rgb(251, 247, 226);
  }

  &[type="checkbox"] {
    width: auto;
    margin-right: 5px;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    top: 3px;

    &:checked {
      background-color: #b212e3;
      border-color: #a600ff;

      &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 6px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  &[type="radio"] {
    width: auto;
    margin-right: 5px;
    appearance: none;
    width: 5px;
    height: 5px;
    border: 1px solid #ccc;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    top: 3px;

    &:checked {
      background-color: #46e312;
    }
  }

  &[type="search"] {
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: 5px 50%;
    background-size: 20px;
  }

  &:read-only {
    background-color: #f5f5f5;
    border-color: #ddd;
    color: #888;
    cursor: not-allowed;
  }
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  color: #727272;
}

.checkboxGroup {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}

.req {
  color: red;
}

select {
  padding: 10px;
  border: 2px solid rgb(29, 29, 29);
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
  width: 240px;
  margin-bottom: 10px;
  background-color: white;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:disabled {
    background-color: #b4b5b6;
    cursor: not-allowed;
  }
}

// Placeholder styling
::placeholder {
  color: #999;
}

// Label styling
label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-family: 'Hero' sans-serif !important;
}

// Error message styling
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

// Text area styling
textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  font-family: "SourceSans";

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:required {
    background-color: rgb(251, 246, 216);
  }
}

.readonly-textarea {
  width: 100%;
  height: 80px;
  border: none;
  resize: none;
  background-color: transparent;
  color: $fg-light;
  font-size: 1em;
  font-family: "SourceSans";
  padding: 5px;
  border-radius: 5px;
  outline: none;
  white-space: pre-wrap;
  user-select: none;
  -webkit-user-select: none;
  cursor: default;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  &:focus {
    cursor: default;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
