@use "./_variables.scss" as *;

.datatable {
  width: 100% !important;
  border-spacing: 0;
}

.datatable-header {
  height: 35px !important;
  background-color: $darkergrey;
  color: white;
  padding-left: 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  .datatable-header-inner {
    height: 100% !important;
  }
}

.datatable-header-row {
  height: 100%;
}

.datatable-header-cell {
  line-height: 2 !important;
  user-select: none;
  -webkit-user-select: none;
  padding-left: 10px;
  min-width: 100px;
  text-align: left;

  &:first-child {
    border-top-left-radius: 25px;
  }

  &:last-child {
    border-right: none;
    background-color: none;
    border-top-right-radius: 25px;
  }
}

.datatable-body {
  background-color: $lightgrey;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  box-sizing: border-box;
}

.datatable-row-wrapper {
  height: 60px;
  box-sizing: border-box;

  &--no-click {
    &:hover {
      background-color: $lightgrey;
      cursor: default;
    }

    &:last-child {
      border-bottom-right-radius: 25px;

      .datatable-body-cell {
        &:first-child {
          border-bottom-left-radius: 25px;
        }

        &:last-child {
          border-bottom-right-radius: 25px;
        }
      }
    }
  }

  &:hover {
    background-color: $flowstructblue;
    cursor: pointer;
  }
  &:last-child {
    border-bottom-right-radius: 25px;

    .datatable-body-cell {
      &:first-child {
        border-bottom-left-radius: 25px;
      }

      &:last-child {
        border-bottom-right-radius: 25px;
      }
    }
  }

  .datatable-body-cell {
    padding-left: 10px;
    text-overflow: ellipsis;
    height: 60px !important;
  }

  &:last-child {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.datatable-scroll {
  width: 100% !important;
}

.datatable-body-row {
  height: 60px !important;
  padding: 10px 10px;
  width: 100% !important;
}

.datatable-row-odd {
  height: 60px;
}

.datatable-row-even {
  height: 60px;
}

.empty-row {
  height: 60px;
  text-align: center;
  color: $darkgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}
