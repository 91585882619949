@use "./colors.scss" as *;

.btn-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  justify-content: flex-start;

  &__center {
    justify-content: center;
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }

  &__end {
    justify-content: flex-end;
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }

  &__between {
    justify-content: space-between;
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }

  &__around {
    justify-content: space-around;
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }

  &__evenly {
    justify-content: space-evenly;
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }

  &__stretch {
    justify-content: stretch;
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }
}

.btn-success {
  background-color: $bg-success;
  color: $fg-success;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    background-color: #218838;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.btn-danger {
  background-color: $bg-danger;
  color: $fg-danger;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    background-color: #c82333;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.btn-primary {
  background-color: $bg-primary;
  color: $fg-primary;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    background-color: #0056b3;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.btn-secondary {
  background-color: $bg-secondary;
  color: $fg-secondary;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    background-color: #6c757d;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.btn-warning {
  background-color: $bg-warning;
  color: $fg-warning;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    background-color: #e0a800;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.btn-dark {
  background-color: $bg-dark;
  color: $fg-dark;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    background-color: #1b1e21;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.btn-light {
  background-color: $bg-light;
  color: $fg-light;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    background-color: #f8f9fa;
  }

  a {
    color: $fg-light;
    text-decoration: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.back-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
  justify-content: flex-start;
  background-color: $bg-dark;
  color: $fg-dark;
  cursor: pointer;
  text-decoration: none;

  &:visited {
    color: #000;
  }
}
