$dataGroupHeaderColor: #3d3d3d;

$bg-primary: #003cff;
$fg-primary: #ffffff;

$bg-secondary: #b120ff;
$fg-secondary: #ffffff;

$bg-success: #00a34c;
$fg-success: #ffffff;

$bg-danger: #ff1e87cd;
$fg-danger: #ffffff;

$bg-warning: #ffc82c;
$fg-warning: #ffffff;

$bg-info: #00d8ff;
$fg-info: #ffffff;

$bg-dark: #3d3d3d;
$fg-dark: #ffffff;

$bg-light: #b5b5b5;
$fg-light: #313131;

$main-gradient: linear-gradient(225deg, #89a9ff 50%,#c559ff 100%);
$register-gradient: linear-gradient(225deg, #b760e641 50%,#c57feb3f 100%);

.primary {
  background-color: $bg-primary;
  color: $fg-primary;
}

.secondary {
  background-color: $bg-secondary;
  color: $fg-secondary;
}

.success {
  background-color: $bg-success;
  color: $fg-success;
}

.danger {
  background-color: $bg-danger;
  color: $fg-danger;
}

.warning {
  background-color: $bg-warning;
  color: $fg-warning;
}

.info {
  background-color: $bg-info;
  color: $fg-info;
}

.dark {
  background-color: $bg-dark;
  color: $fg-dark;
}

.light {
  background-color: $bg-light;
  color: $fg-light;
}
