@use "./styles/_buttons.scss" as *;
@use "./styles/_table.scss" as *;
@use "./styles/_forms.scss" as *;
@use "./styles/_colors.scss" as *;
@use "./styles/_badge.scss" as *;
@use "./styles/_sku.scss" as *;

@font-face {
  font-family: "DMSerifText";
  src: url("/fonts/DMSerifText.ttf") format("truetype");
}

@font-face {
  font-family: "SourceSans";
  src: url("/fonts/SourceSans.ttf") format("truetype");
}

@font-face {
  font-family: "Hero";
  src: url("/fonts/Hero.otf") format("opentype");
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Hero", sans-serif;
  font-weight: 300;
}

p,
span,
a {
  font-family: "SourceSans", sans-serif;
}

body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  font-family: "Open Sans", sans-serif;
}

div,
section,
aside,
article,
header,
footer,
nav,
main {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row-start-stretch {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: stretch;
}

.flex-row-center-spacebetween {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row-center-spacearound {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.flex-row-center-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.flex-row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row-end-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.flex-column-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-column-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}

.flex-column-end-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-40 {
  gap: 40px;
}

.gap-60 {
  gap: 60px;
}

.gap-80 {
  gap: 80px;
}

.wrap {
  flex-wrap: wrap;
}

.no-margin {
  margin: 0;
}

.relative {
  position: relative;
}

.container {
  padding: 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  width: fit-content;
  background-color: #fefefe;
  border-radius: 10px;
  box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.no-display {
  display: none;
}

.width-100 {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.link {
  text-decoration: underline !important;
  color: #fff;

  &:visited {
    color: #fff;
  }
}
