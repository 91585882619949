@use "./colors" as *;

.sub {
  padding: 10px;
  border-radius: 10px;
  font-weight: 800;
  font-size: 1.2rem;

  &.basic {
    background-color: #f0f0f0;
    color: #000;
  }

  &.startup {
    background-color: $bg-primary;
    color: $fg-primary;
  }

  &.pro {
    background-color: $bg-secondary;
    color: $fg-secondary;
  }
}
